import { useFormContext, useWatch } from "react-hook-form";
import { UseMomentProperties } from "../moment-card/types";
import { Moment } from "../moment/types";
import { MomentFormMoment } from "../moment/types/moment-form-moment";

export type ToTarget =
  | {
      toTarget: "channel" | "notify" | "manager" | "employee";
      target_connection_type: null;
    }
  | {
      toTarget: "connection";
      target_connection_type: string;
    };

export function useToTarget(): ToTarget {
  const { control, getValues } = useFormContext<MomentFormMoment>();
  useWatch({
    control,
    name: [
      "only_additional_recipients",
      "target_manager",
      "target_connection_type",
      "target_channel",
      "notify_channel",
    ],
  });
  const [
    only_additional_recipients,
    target_manager,
    target_connection_type,
    target_channel,
    notify_channel,
  ] = getValues([
    "only_additional_recipients",
    "target_manager",
    "target_connection_type",
    "target_channel",
    "notify_channel",
  ]);

  return getTargetType({
    only_additional_recipients,
    target_manager,
    target_connection_type,
    target_channel,
    notify_channel,
  });
}

export function getToTarget(moment: Moment): ToTarget {
  return getTargetType({
    ...moment,
    target_channel: moment.target_channel ?? false,
    target_manager: moment.target_manager ?? false,
    only_additional_recipients: moment.only_additional_recipients ?? false,
    notify_channel: moment.notify_channel ?? false,
  });
}

export const getTargetType = ({
  only_additional_recipients,
  target_manager,
  target_connection_type,
  target_channel,
  notify_channel,
}: Pick<
  UseMomentProperties,
  | "only_additional_recipients"
  | "target_manager"
  | "target_connection_type"
  | "target_channel"
  | "notify_channel"
>): ToTarget => {
  if (target_channel || notify_channel)
    return { toTarget: "channel", target_connection_type: null };

  if (only_additional_recipients) return { toTarget: "notify", target_connection_type: null };

  if (target_manager) return { toTarget: "manager", target_connection_type: null };

  // if any connection is defined, then the type is 'connection'
  if (target_connection_type) return { toTarget: "connection", target_connection_type };

  return { toTarget: "employee", target_connection_type: null };
};
